<template>
  <article
    :style="{
      backgroundImage: `url(${TulipsPng})`,
      backgroundColor: item.backgroundColor || undefined,
    }"
    class="relative rounded-lg bg-[#FF7590] bg-[length:140px] bg-[left_-60px_bottom] bg-no-repeat text-center xl:bg-[length:160px] xl:bg-[left_top_-20px]"
  >
    <div
      class="absolute h-full w-full scale-x-[-1] bg-[length:140px] bg-[left_-60px_bottom] bg-no-repeat xl:bg-[left_top_-20px]"
      :style="{ backgroundImage: `url(${TulipsPng})` }"
    />
    <div
      :style="{ color: item.titleColor || undefined }"
      class="relative flex flex-col items-center space-y-1 px-11 py-3 font-semibold leading-4 text-white xl:leading-6"
    >
      <div v-html="$sanitize(item.title)" />
      <p
        v-if="item.description"
        class="max-w-[590px] text-xs font-medium leading-3"
        v-html="$sanitize(item.description)"
      />
    </div>
    <Btn
      v-if="item.buttonHreff"
      :to="item.buttonHreff"
      :style="{
        backgroundColor: props.item.buttonColor || undefined,
        color: props.item.buttonTextColor || undefined,
      }"
      class="text-4 shadow-container relative mx-auto mb-3 inline-flex h-8 items-center rounded-lg bg-[#FFACBC] px-4 font-medium text-white xl:absolute xl:right-[75px] xl:top-1/2 xl:m-0 xl:-translate-y-1/2"
    >
      {{ item.buttonText || 'Узнать подробности' }}
    </Btn>
    <Btn
      :style="{ color: props.item.buttonColor || undefined }"
      class="absolute right-2 top-2 text-[#FFACBC] xl:right-9 xl:top-1/2 xl:-translate-y-1/2"
      @click="close"
    >
      <Icon name="close-round-duotone" class="h-6 w-6" />
    </Btn>
  </article>
</template>

<script setup lang="ts">
import TulipsPng from '@valta/assets/images/tulips.png'
import type { CrossBanner } from '@/openapi_fetch'

const props = defineProps<{
  item: CrossBanner
}>()
const emit = defineEmits(['close'])

const { $sanitize } = useNuxtApp()

function close() {
  emit('close', props.item)
}
</script>
